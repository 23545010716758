import React from "react"

import Layout from "../components/layout"
import ProjectNav from "../components/project-nav"
import Seo from "../components/seo"
import mockup from "../images/quinova/mockup.png"

import timeline from "../images/quinova/timeline.png"
import projects from "../images/quinova/projects.png"

import moodboard from "../images/quinova/moodboard.jpg"
import wireframe1 from "../images/quinova/wireframe1.jpg"
import wireframe2 from "../images/quinova/wireframe2.jpg"

const SecondPage = () => (
  <Layout>
    <Seo title="Quinova" />
    <div className="light-grey">
      <ProjectNav
        className="small-space-top"
        previous="/dan-flavin"
        next="/uri"
      />
      <div className="content grid space smaller-space-top">
        <div className="col-1">
          <h2>
            Quinova
            <br />
            Webdesign
          </h2>
          <p className="teaser-text">
            This project's goal was to create a responsive webdesign for the
            fictitious architecture firm "Quinova". The idea of the design is to
            keep it quite minimalistic with calm colors and subtle typography -
            such that the work of the architect can stand out. The design
            process was made in Sketch.
          </p>
        </div>
        <img
          className="col-2"
          src={mockup}
          alt="Quinova Website on Desktop, Laptop and Mobile"
        />
      </div>
    </div>
    <div className="content">
      <div className="grid grid--2 space">
        <img className="col-1 shadow" src={timeline} alt="Timeline screen" />
        <img className="col-1 shadow" src={projects} alt="Projects screen" />
      </div>
      <div className="grid space-below">
        <div className="col-1">
          <h3>Moodboard and sketches</h3>
          <p>
            The moodboard summarizes the calming and clean atmosphere the design
            is supposed to create. Fitting to the topic, a color palette
            including earth and natural tones was used. <br />
            Before starting with the final design, wireframes for desktop,
            tablet and mobile devices were created using{" "}
            <a href="https://moqups.com/" target="_blank" rel="noreferrer">
              moqups.com
            </a>
            .
          </p>
        </div>
        <img className="col-2 shadow" src={moodboard} alt="Moodboard" />
      </div>
      <div className="grid space-below">
        <img
          className="col-2"
          src={wireframe1}
          alt="Wireframe example desktop"
        />
        <img
          className="col-1"
          src={wireframe2}
          alt="Wireframe example mobile"
        />
      </div>
    </div>
    <ProjectNav previous="/dan-flavin" next="/uri" className="space-below" />
  </Layout>
)

export default SecondPage
